@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,400;1,500;1,700&display=swap');

@layer base {
  * {
    font-family: 'Roboto', sans-serif;
  }
  body {
    min-height: 100vh;
    width: 100vw;
    overflow-x: hidden;
    overscroll-behavior-y: contain;
    background: linear-gradient(
        #f5f5f5,
        #f5f5f5,
        #f5f5f5,
        #eaf2ff,
        #f5f5f5,
        #eaf2ff,
        #eaf2ff,
        #cfecff,
        #a9e7ff,
        #baf3fb
      )
      center/cover no-repeat fixed;
  }

  @media screen and (min-width: 640px) {
    body {
      background: linear-gradient(
          to right top,
          #00e0ff,
          #76e3ff,
          #a9e7ff,
          #cfecff,
          #eaf2ff,
          #eaf2ff,
          #eaf2ff,
          #eaf2ff,
          #cfecff,
          #a9e7ff,
          #76e3ff,
          #00e0ff
        )
        center/cover no-repeat fixed;
    }
  }
}

@layer base {
  input[type='password'],
  input[type='text'],
  input[type='email'],
  input[type='number'] {
    @apply my-2 mx-auto h-12 rounded-md w-full p-2 border-2 transition;
  }
}
table {
  @apply overflow-hidden;
}
@layer components {
  .errorInput {
    @apply border-red-500 !important;
    @apply border-2 !important;
  }
}
